<template>
  <div class="cameras">
    <v-data-table
      :headers="headers"
      :items="cameras"
      hide-default-footer
      disable-pagination
      :no-data-text="noData"
      v-sortable-data-table
      @sorted="saveOrder"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar elevation="1" height="100">
          <v-dialog v-model="dialog" max-width="500px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-container>
                <v-spacer></v-spacer>
                <div class="text-center">
                  <v-btn color="success" rounded v-bind="attrs" v-on="on">
                    <v-icon>fa-plus</v-icon>
                  </v-btn>
                  <div class="caption">Agregar Cámara</div>
                </div>
                <v-spacer></v-spacer>
              </v-container>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-form ref="form">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Cámara"
                          :rules="requiredRuleName"
                          :color="whiteLabel.colors.primary"
                          @input="inputChanged"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.url"
                          label="URL"
                          :rules="requiredRuleURL"
                          :color="whiteLabel.colors.primary"
                          @input="inputChanged"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-form>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :color="whiteLabel.colors.primary"
                  text
                  @click="close"
                  :disabled="loading"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  @click="save"
                  :loading="loading"
                  :disabled="!isValid"
                >
                  Guardar
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px" persistent>
            <v-card>
              <v-card-title class="headline"
                >Desea eliminar la cámara "{{ editedItem.name }}"?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :color="whiteLabel.colors.primary"
                  text
                  @click="closeDelete"
                  :disabled="loading"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="red darken-1"
                  text
                  @click="deleteItemConfirm"
                  :loading="loading"
                  >Eliminar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.dragRow="{}">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" dense class="sortHandler">
              mdi-drag-horizontal-variant
            </v-icon>
          </template>
          <span>Ir al Residente</span>
        </v-tooltip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-2"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { db, firebase } from "@/fb";
import { mapGetters } from "vuex";
import Sortable from "sortablejs";

export default {
  data: () => ({
    loading: false,
    cameras: [],
    snackbar: {
      visible: false,
      timeout: 2000,
      text: "",
      color: "",
    },
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "",
        align: "start",
        sortable: false,
        value: "dragRow",
        width: "1%",
      },
      { text: "Cámara", align: "start", value: "name" },
      { text: "URL", align: "start", value: "url" },
      { text: "Acciones", value: "actions", align: "end" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      url: "",
      id: "",
    },
    defaultItem: {
      name: "",
      url: "",
      id: null,
    },
    noData: "Buscando cámaras ...",
    requiredRuleName: [(v) => !!v || "El Nombre es requerido"],
    requiredRuleURL: [(v) => !!v || "La URL es requerida"],
    isValid: false,
    disabled: true,
  }),

  mounted() {
    this.snackbar.visible = true;
    this.snackbar.text = "Buscando cámaras ...";
    this.snackbar.color = "success";
    this.getCameras();
  },

  computed: {
    ...mapGetters({
      user: "user",
      whiteLabel: "whiteLabel",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Cámara" : "Editar Cámara";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    async getCameras() {
      try {
        await db
          .collection("buildings/" + this.user.data.building + "/cameras")
          .get()
          .then((cameras) => {
            this.cameras = [];
            let camera_positions = null;
            this.noData = "";

            if (!cameras.empty) {
              db.doc("buildings/" + this.user.data.building)
                .get()
                .then((camera) => {
                  camera_positions = camera.data().camera_positions;
                  let unsorted_cameras = [];
                  cameras.forEach((camera) => {
                    unsorted_cameras.push(camera);
                  });
                  camera_positions.forEach((camera) => {
                    let c = unsorted_cameras.find((c) => c.id === camera);
                    this.cameras.push({
                      name: c.data().name,
                      url: c.data().url,
                      id: c.id,
                    });
                  });
                });
            } else {
              this.noData = "No hay cámaras existentes";
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    editItem(item) {
      this.isValid = false;
      this.editedIndex = this.cameras.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.cameras.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loading = true;
      this.snackbar.visible = true;
      this.snackbar.text = "Eliminando cámara ...";
      this.snackbar.color = "success";
      db.doc(
        "buildings/" +
          this.user.data.building +
          "/cameras/" +
          this.editedItem.id
      )
        .delete()
        .then(() => {
          db.doc("buildings/" + this.user.data.building).update({
            camera_positions: firebase.firestore.FieldValue.arrayRemove(
              this.editedItem.id
            ),
          });
          this.snackbar.visible = true;
          this.snackbar.text = "Cámara eliminada...";
          this.snackbar.color = "success";
          this.cameras.splice(this.editedIndex, 1);
          this.loading = false;
          this.closeDelete();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.isValid = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      // Edicion
      if (this.editedIndex > -1) {
        this.loading = true;
        this.snackbar.visible = true;
        this.snackbar.text = "Cambios guardados...";
        this.snackbar.color = "success";
        let id = this.editedItem.id;
        let name = this.editedItem.name;
        let url = this.editedItem.url;
        db.doc("buildings/" + this.user.data.building + "/cameras/" + id)
          .update({ name: name, url: url })
          .then(() => {
            this.loading = false;
            Object.assign(this.cameras[this.editedIndex], this.editedItem);
            this.close();
          });
      } else {
        // Creacion
        this.loading = true;
        this.snackbar.visible = true;
        this.snackbar.text = "Agregado cámara ...";
        this.snackbar.color = "success";
        let name = this.editedItem.name;
        let url = this.editedItem.url;

        db.collection("buildings/" + this.user.data.building + "/cameras/")
          .add({ name: name, url: url })
          .then((camera) => {
            db.doc("buildings/" + this.user.data.building)
              .update({
                camera_positions: firebase.firestore.FieldValue.arrayUnion(
                  camera.id
                ),
              })
              .then(() => {
                this.loading = false;
                this.cameras.push({ name: name, url: url, id: camera.id });
                this.close();
              });
          });
      }
    },

    inputChanged() {
      if (this.$refs.form.validate()) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    },

    saveOrder(event) {
      this.snackbar.visible = true;
      this.snackbar.text = "Orden de cámaras actualizado ...";
      this.snackbar.color = "success";
      const movedItem = this.cameras.splice(event.oldIndex, 1)[0];
      this.cameras.splice(event.newIndex, 0, movedItem);
      const new_positions = [];
      this.cameras.forEach((camera) => {
        new_positions.push(camera.id);
      });
      db.doc("buildings/" + this.user.data.building)
        .update({ camera_positions: new_positions })
        .then(() => {
          //
        });
    },
  },

  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          handle: ".sortHandler",
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.sortHandler {
  &:hover {
    cursor: ns-resize;
  }
}
</style>
